import React, { useEffect, useState } from 'react'
import './Welcome.scss'
import { useTranslation } from 'react-i18next'
import { Checkbox, Image, Input, Mask, Toast } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router-dom'
import { setLoading } from 'redux/reducer/common'
import { useAppDispatch } from 'hooks/reduxHooks'
import banner from 'static/images/banner.png'
import appIcon from 'static/images/login_scan.png'
import boschLogo from 'static/images/logo.png'
import fillInfo from 'static/images/Fill.png'
import replace from 'react-string-replace'
import classNames from 'classnames'
import service from 'service/pages/referral'
import { setRequestUrl } from '../../redux/reducer/login'
import utils from '../../utils/utils'
import ic_paperlessWarranty from 'static/images/ic_paperless_warranty.png'
import ic_warrantyExtension from 'static/images/ic_warranty_extension.png'
import ic_Information from 'static/images/ic_information.png'
import ic_hotDeals from 'static/images/ic_hot_deals.png'
import ic_genuine from 'static/images/ic_genuine_product_confirmation.png'
import ic_dealer from 'static/images/ic_dealer.png'
import Captcha from '../../component/captcha'

const RegisterForUser: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [inviteName, setInviteName] = useState<string>('    ')
  const [code, setCode] = useState<string>('')
  const [tcChecked, setTcChecked] = useState<boolean>(false)
  const [ppChecked, setPpChecked] = useState<boolean>(false)
  const [marketingChecked, setMarketingChecked] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const childRef = React.useRef<any>()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  // 获取所有的查询参数
  // 参数说明 shareInfo、country、language、inviteName、baseurl、from
  // shareInfo 由App端生成的分享链接中带过来的信息,包含邀请人信息
  // country 国家代码 如：CN、BR等
  // language 当前语言
  // inviteName from为refer时独有 邀请人信息
  // baseurl 接口请求域名,与当前环境对应,分global和CN
  // from 链接来源 目前有两种值: 1、refer: App端邀请用户注册的来源 2、scanProduct: 扫描工具二维码
  const queryParams: { [key: string]: string } = {}
  const paramsArray = Array.from(searchParams)
  for (const [key, value] of paramsArray) {
    queryParams[key] = value
  }
  const debugShareInfo =
    'eyJjb3VudHJ5IjoiKzg2IiwibG9naW5JZCI6IjEwOTY5NiIsIm1vYmlsZSI6Imk1VEtUb0FzejM1emJQN3ZZUFdlb2xGRTRiTkcxcld5QlVPcC9Qdkh6Rml1YlRpT0ZrWTUvekdmYlJkY2tzWkwiLCJuYW1lIjoiY29uc3VtZXIiLCJjb3VudHJ5VmFsdWUiOiJDTiJ9'

  useEffect(() => {
    dispatch(
      setRequestUrl(
        queryParams.baseurl ? queryParams.baseurl : 'missingBaseurl'
      )
    )
    i18n
      .changeLanguage(
        queryParams.language ? queryParams.language : navigator.language
      )
      .then(() => {})
    if (queryParams.inviteName) {
      setInviteName(queryParams.inviteName as string)
    }
  }, [])

  const joinBtnClicked = () => {
    if (!tcChecked || !ppChecked) {
      Toast.show({
        icon: 'fail',
        content: t('referral.tc'),
        position: 'top',
        duration: 2000,
      })
      return
    }
    let sms = 'N'
    if (marketingChecked) {
      sms = 'Y'
    }
    if (!code) {
      Toast.show({
        icon: 'fail',
        content: t('registerForUser.wrongCaptcha'),
        position: 'top',
        duration: 2000,
      })
      return
    }
    console.log('clicked join btn code', code)
    const result = childRef.current.validate(code)
    console.log('clicked join btn result', result)
    if (!result) {
      Toast.show({
        icon: 'fail',
        content: t('registerForUser.wrongCaptcha'),
        position: 'top',
        duration: 2000,
      })
      return
    }

    // let jsonData = {
    //   data: state?.shareInfo ? state?.shareInfo : debugShareInfo,
    //   code: code,
    //   sms: sms,
    // }
    let urlData = queryParams.shareInfo ? queryParams.shareInfo : debugShareInfo
    dispatch(setLoading(true))
    service
      .submitAuthorization(urlData as any, sms)
      .then((res: any) => {
        let data = res.data
        console.log('submitRes', data)
        if (data) {
          // UIkit.modal('#modal-center').show();
          setVisible(true)
        }
      })
      .catch((err) => {
        childRef.current.refresh()
        console.log('referErr', err)
        if (err?.response.status === 409) {
          Toast.show({
            icon: 'fail',
            content: t('registerForUser.authorized'),
            position: 'top',
            duration: 2000,
          })
        } else if (err?.messageList?.length > 0) {
          Toast.show({
            icon: 'fail',
            content: t('errorCode.' + err?.messageList[0].content),
            position: 'top',
            duration: 2000,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: t('referral.error'),
            position: 'top',
            duration: 2000,
          })
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const jumpToDownload = () => {
    let item = utils.getCurrentEnvConfig()
    window.location.href = `${item.webServer}/distribute?country=${
      queryParams.country ? queryParams.country : 'unknown'
    }&app=consumer&auto=1`
  }

  const highlightWord = (match: string, i: number) => (
    <span key={i} className="sellerInviteHighlight">
      <div className="icon">&#xe29b;</div>
      {match}
    </span>
  )

  const replacedText = replace(
    t('registerForUser.inviteMessage', { name: inviteName }) as string,
    inviteName,
    highlightWord
  )

  const highlightTc = (match: string, i: number) => (
    <span
      onClick={() => {
        showtcpp('tc')
      }}
      key={i}
      className="tcPpHighlight"
    >
      {match}
    </span>
  )

  const highlightPp = (match: string, i: number) => (
    <span
      onClick={() => {
        showtcpp('Pp')
      }}
      key={i}
      className="tcPpHighlight"
    >
      {match}
    </span>
  )

  const replacedTc = replace(
    t('referral.TCMessage') as string,
    t('referral.TCTitle') as string,
    highlightTc
  )

  const replacedPp = replace(
    t('referral.PPMessage') as string,
    t('referral.PPTitle') as string,
    highlightPp
  )

  const codeOnChange = (e: any) => {
    setCode(e)
  }

  const tcChange = (e: any) => {
    setTcChecked(e)
  }

  const ppChange = (e: any) => {
    setPpChecked(e)
  }

  const marketingChange = (e: any) => {
    setMarketingChecked(e)
  }

  const showtcpp = (type: string) => {
    console.log('clickedTcpp')
    if (type === 'tc') {
      navigate('/consumer/clause', {
        state: {
          type: '0',
          country: queryParams.country ? queryParams.country : 'CN',
        },
      })
    } else {
      navigate('/consumer/clause', {
        state: {
          type: '1',
          country: queryParams.country ? queryParams.country : 'CN',
        },
      })
    }
  }

  const renderFucs = () => {
    let arr = [
      {
        icon: <Image className="funcsImage" src={ic_paperlessWarranty} />,
        title: t('registerForUser.paperlessWarranty'),
      },
      {
        icon: <Image className="funcsImage" src={ic_warrantyExtension} />,
        title: t('registerForUser.warrantyExtension'),
      },
      {
        icon: <Image className="funcsImage" src={ic_Information} />,
        title: t('registerForUser.productCatalogue'),
      },
      {
        icon: <Image className="funcsImage" src={ic_hotDeals} />,
        title: t('registerForUser.hotDeals'),
      },
      {
        icon: <Image className="funcsImage" src={ic_dealer} />,
        title: t('registerForUser.dealerServiceLocator'),
      },
      {
        icon: <Image className="funcsImage" src={ic_genuine} />,
        title: t('registerForUser.genuineProductInformation'),
      },
    ]
    return (
      <div className="funcsContent">
        <span className="iconText funcsTitle">
          {t('registerForUser.installTips')}
        </span>
        {arr.map((item, index) => {
          return (
            <div
              className={classNames([
                'iconContent',
                index === 0 ? 'firstIconContent' : '',
              ])}
            >
              {item.icon}
              <span className="iconText">{item.title}</span>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <>
      <div className="welcomeRoot">
        <Image className={'banner'} src={banner} fit={'fill'} />
        <div className="welcomeContainer">
          <Image className={'boschLogo'} src={boschLogo} fit={'contain'} />
          <Image className={'appIcon'} src={appIcon} fit={'fill'} />
          <span style={{ marginTop: '2.8rem' }} className="span_bl">
            {t('welcome.welcomeTo')}
          </span>
          <span className="span_bl">{t('welcome.beconnected')}</span>
          <div style={{ minHeight: '3rem', flex: 1 }} />
          <div className="referralBottomContainer bottomContainer">
            <span className="inviteMessage">{replacedText}</span>
            <div className="phoneContainer">
              <Input
                className="captchaInput"
                value={code}
                onChange={codeOnChange}
                placeholder={t('registerForUser.invitePlaceHolder') as string}
              />
              <Captcha className="captchaImg" cRef={childRef} />
            </div>
            <div className="joinBtn" onClick={joinBtnClicked}>
              {t('survey.submit')}
            </div>
            <div className="tcPpText">
              <Checkbox
                checked={tcChecked}
                onChange={tcChange}
                // className="tcPpText"
              />
              <span style={{ marginLeft: '1rem' }}>{replacedTc}</span>
            </div>
            <div className="tcPpText">
              <Checkbox checked={ppChecked} onChange={ppChange} />
              <span style={{ marginLeft: '1rem' }}>{replacedPp}</span>
            </div>
            <div className="tcPpText">
              <Checkbox checked={marketingChecked} onChange={marketingChange} />
              <span style={{ marginLeft: '1rem' }}>
                {t('referral.subscribeMessage')}
              </span>
            </div>
            <div className="divider" />
            {renderFucs()}
            {/*<span className="subscribeTips">{t('welcome.subscribeTips')}</span>*/}
            {/*<div className="subscribeBtn" onClick={onSubscribeClicked}>*/}
            {/*  {t('welcome.subscribeTitle')}*/}
            {/*</div>*/}
            <span className="allRightInfo">{t('welcome.allRightInfo')}</span>
          </div>
        </div>
      </div>
      <Mask
        className={'thanksMask'}
        visible={visible}
        onMaskClick={() => setVisible(false)}
        opacity="thick"
      >
        <div className={'thanksContent'}>
          <Image className={'fillInfo'} src={fillInfo} fit={'contain'} />
          <span className={'thanksMessage'}>{t('registerForUser.thanks')}</span>
          <span className={'installMessage'}>
            {t('registerForUser.install')}
          </span>
          <div className="installBtn" onClick={jumpToDownload}>
            {t('registerForUser.open')}
          </div>
          <div className="laterBtn" onClick={() => setVisible(false)}>
            {t('registerForUser.later')}
          </div>
        </div>
      </Mask>
    </>
  )
}
export default RegisterForUser
