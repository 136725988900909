// @ts-ignore
import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import store, { RootState } from 'redux/store'
import { setLoading } from 'redux/reducer/common'
import utils from '../utils/utils'

//创建axios实例
const service = axios.create({
  timeout: 30000,
  baseURL: '',
})

service.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const state: RootState = store.getState()
    const access_token = state.loginReducer.token
    const requestUrl = state.loginReducer.requestUrl
    config.baseURL = `${requestUrl}/api`
    if (config.changeBaseURL) {
      config.baseURL = utils.getCurrentEnvConfig().chinaBaseurl + '/api'
    }
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`
    }
    if (config.notNeedToken) {
      config.headers.Authorization = ''
    }
    config.headers = {
      ...config.headers,
    }
    return config
  },
  function (error: AxiosError) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
//axios返回格式
interface axiosTypes<T> {
  data: T
  status: number
  statusText: string
}

//后台响应数据格式

// interface responseTypes<T> {
//   status: number
//   data: T
// }

//核心处理代码 将返回一个promise 调用then将可获取响应的业务数据
export const requestHandler = <T>(
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  params: object = {},
  config: AxiosRequestConfig = {}
): Promise<T> => {
  let response: Promise<axiosTypes<T>>
  switch (method) {
    case 'get':
      response = service.get(url, config)
      break
    case 'post':
      response = service.post(url, params, config)
      break
    case 'put':
      response = service.put(url, params, config)
      break
    case 'delete':
      response = service.delete(url, config)
      break
  }

  return new Promise<T>((resolve, reject) => {
    response
      .then((res) => {
        //业务代码 可根据需求自行处理
        const data = res.data
        if (res.status !== 200) {
          store.dispatch(setLoading(false))
          reject(data)
        } else {
          //数据请求正确 使用resolve将结果返回
          store.dispatch(setLoading(false))
          resolve(data)
        }
      })
      .catch((error) => {
        store.dispatch(setLoading(false))
        reject(error)
      })
  })
}
