import { post } from '../../axios/request'
import { miniProgramUrlRequestDto } from '../../interface/distribute/TurnToWechatMiniProgram'
// @ts-ignore
import { AxiosRequestConfig } from '../../axios'

const basic = '/oauth-service'

export const getMiniProgramUrlScheme = (param: miniProgramUrlRequestDto) => {
  let config: AxiosRequestConfig = {
    changeBaseURL: true,
    notNeedToken: true,
  }
  const url = `${basic}/wechat/miniProgramUrlScheme`
  return post(url, param, config)
}
