import { get } from '../../axios/request'
// @ts-ignore
import { AxiosRequestConfig } from 'axios'

const basic = '/masterdata-service'

export const getLocationFromBaiduApiByIp = () => {
  const url = `${basic}/location/getLocationIpFromBaiduApi/baidu`
  let config: AxiosRequestConfig = {
    changeBaseURL: true,
    notNeedToken: true,
  }
  return get(url, {}, config)
}
