import React, { useState } from 'react'
import './surveyComponent.scss'
import { Radio, Checkbox, Toast, Rate, TextArea } from 'antd-mobile'
import { useTranslation } from 'react-i18next'

interface IOption {
  number: number
  description: string
}

interface IQuestion {
  number: number
  title: string
  type: string
  mandatory: boolean
  options: Array<IOption>
  len: number
  swipePrev: Function
  swipeNext: Function
  submit: Function
  questionnaireId: number
}

export interface ISubmitQuestion {
  questionnaireId: number
  type: string
  questionNumber: number
  content: string | number | number[]
}

export enum Type {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  RATING = 'RATING',
  COMPLETION = 'COMPLETION',
}

const SurveyComponent: React.FC<IQuestion> = ({
  number,
  title,
  type,
  mandatory,
  options,
  len,
  swipePrev,
  swipeNext,
  submit,
  questionnaireId,
}) => {
  const [value, setValue] = useState<number>(0)
  const [rate, setRate] = useState<number>(0)
  const [multiValue, setMultiValue] = useState<number[]>([])
  const [completion, setCompletion] = useState<string>('')

  const { t } = useTranslation()

  const clickNextOrSubmit = (nextOrSubmit: Function) => {
    let data: ISubmitQuestion = {
      questionnaireId,
      type,
      questionNumber: number,
    } as ISubmitQuestion
    if (!!value && type === Type.SINGLE_CHOICE) {
      data.content = value
    }
    console.log(multiValue.length)
    if (multiValue.length > 0 && type === Type.MULTIPLE_CHOICE) {
      data.content = multiValue
    }
    if (rate > 0 && type === Type.RATING) {
      data.content = rate
    }
    if (completion.length > 0 && type === Type.COMPLETION) {
      data.content = completion
    }
    if (mandatory) {
      if (
        (!value && type === Type.SINGLE_CHOICE) ||
        (multiValue.length === 0 && type === Type.MULTIPLE_CHOICE) ||
        (rate === 0 && type === Type.RATING) ||
        (completion.length === 0 && type === Type.COMPLETION)
      ) {
        if (type === Type.COMPLETION) {
          Toast.show(t('survey.pleaseEnter')!)
        } else {
          Toast.show(t('survey.pleaseChoose')!)
        }
      } else {
        nextOrSubmit(data)
      }
    } else {
      nextOrSubmit(data)
    }
  }

  const footer = () => {
    if (number === 1) {
      return (
        <div
          onClick={() => clickNextOrSubmit(swipeNext)}
          className={'footerFirst'}
        >
          &#xe093;
        </div>
      )
    }
    if (number !== 1 && number !== len) {
      return (
        <>
          <div onClick={() => swipePrev()} className={'footerMiddle'}>
            &#xe090;
          </div>
          <div
            onClick={() => clickNextOrSubmit(swipeNext)}
            className={'footerMiddle'}
          >
            &#xe093;
          </div>
        </>
      )
    }
    if (number === len) {
      return (
        <div className={'footerLast'}>
          <span className={'submitTips'}> {t('survey.submitTips')}</span>
          <div className={'footer'}>
            <div onClick={() => swipePrev()} className={'footerMiddle'}>
              &#xe090;
            </div>
            <div
              onClick={() => clickNextOrSubmit(submit)}
              className={'footerMiddle'}
            >
              {t('survey.submit')}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={'SurveyComponentContent'}>
      <span className={'title'}>
        {mandatory ? <span className={'require'}>*</span> : null}
        {number}
        {'. '}
        {title}
        {type === Type.MULTIPLE_CHOICE ? (
          <span> {t('survey.multipleChoice')}</span>
        ) : null}
      </span>
      {type === Type.SINGLE_CHOICE ? (
        <div className={'single'}>
          <Radio.Group
            value={value}
            onChange={(val) => {
              setValue(val as number)
            }}
          >
            {options.map((option) => {
              return (
                <div
                  key={option.number}
                  className={
                    value === option.number ? 'option selectedOption' : 'option'
                  }
                >
                  <span
                    className={
                      value === option.number
                        ? 'description selectedDescription'
                        : 'description'
                    }
                  >
                    {option.description}
                  </span>
                  <Radio className={'number'} value={option.number} />
                </div>
              )
            })}
          </Radio.Group>
        </div>
      ) : null}
      {type === Type.MULTIPLE_CHOICE ? (
        <div className={'multi'}>
          <Checkbox.Group
            value={multiValue}
            onChange={(val) => {
              setMultiValue(val as number[])
            }}
          >
            {options.map((option) => {
              return (
                <div
                  key={option.number}
                  className={
                    multiValue.includes(option.number)
                      ? 'option selectedOption'
                      : 'option'
                  }
                >
                  <span
                    className={
                      multiValue.includes(option.number)
                        ? 'description selectedDescription'
                        : 'description'
                    }
                  >
                    {option.description}
                  </span>
                  <Checkbox className={'number'} value={option.number} />
                </div>
              )
            })}
          </Checkbox.Group>
        </div>
      ) : null}
      {type === Type.RATING ? (
        <div className={'rating'}>
          <Rate className={'score'} onChange={(val) => setRate(val)} />
        </div>
      ) : null}
      {type === Type.COMPLETION ? (
        <div className={'completion'}>
          <div className={'textDiv'}>
            <TextArea
              className={'text'}
              value={completion}
              onChange={(val) => {
                setCompletion(val)
              }}
              autoSize={{ minRows: 5, maxRows: 7 }}
              maxLength={500}
              showCount
            />
          </div>
        </div>
      ) : null}
      <div className={'footer'}>{footer()}</div>
    </div>
  )
}
SurveyComponent.defaultProps = {}
export default SurveyComponent
