import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NoMatch: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (isWeChatBrowser()) {
      navigate(`/distribute/turnToWechatMiniProgram`)
      console.log('当前在微信浏览器环境')
    } else {
      navigate(`/distribute`)
      console.log('当前不在微信浏览器环境')
    }
  }, [])

  const isWeChatBrowser = (): boolean => {
    const ua: string = navigator.userAgent.toLowerCase()
    return ua.indexOf('micromessenger') !== -1
  }
  return <div />
}
export default NoMatch
