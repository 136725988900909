import React, { useEffect, useState } from 'react'
import './Welcome.scss'
import { useLocation } from 'react-router-dom'
import service from 'service/pages/referral'

const Clause: React.FC = () => {
  const { state } = useLocation()
  const [htmlContent, setHtmlContent] = useState<any>('')

  useEffect(() => {
    console.log('useLocations', state)
    service
      .getClause(state.type as string, state.country as string)
      .then((res: any) => {
        let data = res.data
        setHtmlContent(data?.content || '')
      })
  }, [])

  return (
    <div
      className="ClauseRoot"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  )
}
export default Clause
