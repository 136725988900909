import React, { useEffect } from 'react'
import './Welcome.scss'
import { useTranslation } from 'react-i18next'
import { Image } from 'antd-mobile'
import { useLocation } from 'react-router-dom'
import banner from 'static/images/banner.png'
import appIcon from 'static/images/login_scan.png'
import boschLogo from 'static/images/logo.png'
import utils from '../../utils/utils'

const Welcome: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation()

  useEffect(() => {
    console.log('typetype', state?.type)
  }, [])
  const joinBtnClicked = () => {
    console.log('clicked join btn')
    let item = utils.getCurrentEnvConfig()
    let distributeHref = `${item.webServer}/distribute?country=${
      state?.country ? state?.country : 'unknown'
    }&app=consumer&auto=1`
    window.location.href = distributeHref
  }
  // const onSubscribeClicked = () => {
  //   console.log('onSubscribeClicked')
  // }

  return (
    <div className="welcomeRoot">
      <Image className={'banner'} src={banner} fit={'fill'} />
      <div className="welcomeContainer">
        <Image className={'boschLogo'} src={boschLogo} fit={'contain'} />
        <Image className={'appIcon'} src={appIcon} fit={'fill'} />
        <span style={{ marginTop: '2.8rem' }} className="span_bl">
          {t('welcome.welcomeTo')}
        </span>
        <span className="span_bl">{t('welcome.beconnected')}</span>
        <div style={{ minHeight: '3rem', flex: 1 }} />
        <div className="bottomContainer">
          <div className="joinBtn" onClick={joinBtnClicked}>
            {t('welcome.joinBeconnected')}
          </div>
          <div className="divider" />
          {/*<span className="subscribeTips">{t('welcome.subscribeTips')}</span>*/}
          {/*<div className="subscribeBtn" onClick={onSubscribeClicked}>*/}
          {/*  {t('welcome.subscribeTitle')}*/}
          {/*</div>*/}
          <span className="allRightInfo">{t('welcome.allRightInfo')}</span>
        </div>
      </div>
    </div>
  )
}
export default Welcome
