import { IServerConfig } from './interface'

const getCurrentEnvConfig = (): IServerConfig => {
  const config = [
    {
      webServer: 'https://q1.bosch-ptqr.com',
      surveyServer: 'https://becoq-web.bosch-ptqr.com',
      chinaBaseurl: 'https://adminq1.bosch-ptqr.com',
      globalBaseurl: 'https://becoq.bosch-ptqr.com',
    },
    {
      webServer: 'https://q2.bosch-ptqr.com',
      surveyServer: 'https://becoq2-web.bosch-ptqr.com',
      chinaBaseurl: 'https://adminq2.bosch-ptqr.com',
      globalBaseurl: 'https://becoq2.bosch-ptqr.com',
    },
    {
      webServer: 'https://d.bosch-ptqr.com',
      surveyServer: 'https://becod-web.bosch-ptqr.com',
      chinaBaseurl: 'https://admind.bosch-ptqr.com',
      globalBaseurl: 'https://becod.bosch-ptqr.com',
    },
    {
      webServer: 'https://bosch-ptqr.com',
      surveyServer: 'https://beco-web.bosch-ptqr.com',
      chinaBaseurl: 'https://admin.bosch-ptqr.com',
      globalBaseurl: 'https://beco.bosch-ptqr.com',
    },
    {
      webServer: 'https://d.bosch-ptqr.com',
      surveyServer: 'https://localhost',
      chinaBaseurl: 'https://admind.bosch-ptqr.com',
      globalBaseurl: 'https://becod.bosch-ptqr.com',
    },
  ]
  const baseUrl: string = 'https://' + window.location.hostname

  let v: IServerConfig = {}
  config.forEach((item: IServerConfig) => {
    if (item.surveyServer === baseUrl || item.webServer === baseUrl) {
      v = item
    }
  })
  console.log('baseUrl', v)
  return v
}

const IsRAFCountryWithCode = (countryCode: string) => {
  /*
  * RAF Countries and Regions:
    Kenya (肯尼亚) - KE
    Morocco (摩洛哥) - MA
    South Africa (南非) - ZA
    Nigeria (尼日利亚) - NG
    Tunisia (突尼斯) - TN
  * */
  let RAFCountryCodes = ['KE', 'MA', 'ZA', 'NG', 'TN']
  return RAFCountryCodes.includes(countryCode)
}

export default { getCurrentEnvConfig, IsRAFCountryWithCode }
