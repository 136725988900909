import React, { useEffect, useState } from 'react'
import './TurnToWechatMiniProgram.scss'
import banner from 'static/images/banner.png'
import logo from 'static/images/logo.png'
import service from 'service/pages/turnToWechatMiniProgram'
import { getMiniProgramQrCodeParam } from 'interface/distribute/TurnToWechatMiniProgram'
import { Button, Image } from 'antd-mobile'
import { setLoading } from '../../redux/reducer/common'
import { useAppDispatch } from '../../hooks/reduxHooks'

const TurnToWechatMiniProgram: React.FC = () => {
  const [imageData, setImageData] = useState<string>('')
  const [openLink, setOpenLink] = useState<string>('')
  const dispatch = useAppDispatch()

  const [timerId, setTimerId] = useState<number | null>(null)

  useEffect(() => {
    dispatch(setLoading(true))
    let param: getMiniProgramQrCodeParam = {
      scene: 'visitor',
      page: 'pages/tabBar/home/home',
      is_hyaline: true,
    }
    let getMiniProgramQrCodeService = service
      .getMiniProgramQrCode(param)
      .then((response) => {
        const imgUrl = `data:image/png;base64,${btoa(
          new Uint8Array(response as ArrayBufferLike).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )}`
        setImageData(imgUrl)
      })
    let getMiniProgramUrlSchemeService = service
      .getMiniProgramUrlScheme({})
      .then((res: any) => {
        if (res?.data?.errmsg === 'ok' && res?.data?.errcode === '0') {
          setOpenLink(res.data.openlink)
          startDelayedMethod(res.data.openlink)
        }
      })
    Promise.all([getMiniProgramQrCodeService, getMiniProgramUrlSchemeService])
      .then()
      .finally(() => {
        dispatch(setLoading(false))
      })

    return (): void => {
      if (timerId) {
        window.clearTimeout(timerId)
      }
    }
  }, [])

  const delayedMethod = (link: string): void => {
    console.log(link, 'link')
    window.location.href = link
  }

  const startDelayedMethod = (link: string): void => {
    const id = window.setTimeout(() => {
      delayedMethod(link)
    }, 5000)
    setTimerId(id)
  }

  const cancelDelayedMethod = (): void => {
    if (timerId) {
      window.clearTimeout(timerId)
      setTimerId(null)
    }
  }

  return (
    <div className="wechatRoot">
      <img className="banner" src={banner} alt="banner" />
      <div>
        <img className="logo" src={logo} alt="logo" />
      </div>
      <div className="mainContent">
        <div className="infoIcon">&#xe64c;</div>
        <p className="notice">通知</p>
        <p className="noticeSon1">
          博世"具好用"小程序惊喜上线，长按图片识别，或扫小程序码体验更多功能。
        </p>
        <p className="noticeSon2">奖励"具"多，就是好用！</p>
        {imageData && <Image className="MiniProgramCode" src={imageData} />}
        <div className="nextButtonDiv">
          <Button
            block
            className="nextButton"
            size="large"
            color="primary"
            onClick={() => {
              window.location.href = openLink
              cancelDelayedMethod()
            }}
          >
            点击前往"具好用"小程序
          </Button>
        </div>
      </div>
    </div>
  )
}
export default TurnToWechatMiniProgram
