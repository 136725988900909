import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CommonState {
  loading: boolean
}

const initialState: CommonState = {
  loading: false,
}

export const common = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoading } = common.actions
export default common.reducer
