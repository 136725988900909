import { getCountryCellPhoneCode, getClause } from '../basic-service'
import { submitReferral, sendReferralCode, submitAuthorization } from '../user-service'

export default {
  getCountryCellPhoneCode,
  submitReferral,
  sendReferralCode,
  getClause,
  submitAuthorization
}
