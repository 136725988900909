import {
  SURVEY,
  SURVEY_SUBMIT_SUCCESS,
  VISITOR_TOUR_INDEX,
  WELCOME_REFERRAL,
  REFERRAL_BY_USER,
  CONSUMER_CLAUSE,
  REGISTER_FOR_USER,
  NO_MATCH,
  DISTRIBUTE,
  TURN_TO_WECHAT_MINI_PROGRAM,
} from './Routers'
import Survey from 'pages/survey/Survey'
import SurveySubmitSuccess from 'pages/survey/SurveySubmitSuccess'
import VisitTour from 'pages/referral/VisitTour'
import Welcome from 'pages/referral/Welcome'
import ReferralByUser from 'pages/referral/Referral'
import Clause from '../pages/referral/Clause'
import RegisterForUser from '../pages/referral/Invite'
import NoMatch from '../pages/common/NoMatch'
import Distribute from '../pages/distribute/Distribute'
import TurnToWechatMiniProgram from '../pages/distribute/TurnToWechatMiniProgram'
import React from 'react'

interface IRouterMap {
  path: string
  component: React.ReactElement
}

const routerMap: Array<IRouterMap> = [
  {
    path: NO_MATCH,
    component: <NoMatch />,
  },
  {
    path: DISTRIBUTE,
    component: <Distribute />,
  },
  {
    path: TURN_TO_WECHAT_MINI_PROGRAM,
    component: <TurnToWechatMiniProgram />,
  },
  {
    path: SURVEY,
    component: <Survey />,
  },
  {
    path: SURVEY_SUBMIT_SUCCESS,
    component: <SurveySubmitSuccess />,
  },
  {
    path: VISITOR_TOUR_INDEX,
    component: <VisitTour />,
  },
  {
    path: WELCOME_REFERRAL,
    component: <Welcome />,
  },
  {
    path: REFERRAL_BY_USER,
    component: <ReferralByUser />,
  },
  {
    path: CONSUMER_CLAUSE,
    component: <Clause />,
  },
  {
    path: REGISTER_FOR_USER,
    component: <RegisterForUser />,
  },
]
export default routerMap
