export const NO_MATCH = '*'
export const SURVEY = '/survey/:language/:surveyStr/:token/:requestUrl'
export const SURVEY_SUBMIT_SUCCESS = '/surveySubmitSuccess/:rewardData'
export const VISITOR_TOUR_INDEX = '/referral/visitorTourIndex'
export const WELCOME_REFERRAL = '/referral/welcomeReferral'
export const REFERRAL_BY_USER = '/referral/referralByUser'
export const CONSUMER_CLAUSE = '/consumer/clause'
export const REGISTER_FOR_USER = '/referral/registerForUser'
export const DISTRIBUTE = '/distribute'
export const TURN_TO_WECHAT_MINI_PROGRAM = '/distribute/turnToWechatMiniProgram'
