import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import routerMap from './RouterMap'

const SurveyRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routerMap.map((m) => {
          return <Route path={m.path} element={m.component} key={m.path} />
        })}
      </Routes>
    </BrowserRouter>
  )
}
export default SurveyRouter
