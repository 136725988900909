import React, { useEffect, useState, useRef } from 'react'
import './Survey.scss'
import { useTranslation } from 'react-i18next'
import { ProgressBar, Swiper, SwiperRef, Toast } from 'antd-mobile'
import SurveyComponent, { Type } from 'component/surveyComponent'
import _ from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
import { setToken, setRequestUrl } from 'redux/reducer/login'
import { setLoading } from 'redux/reducer/common'
import service from 'service/pages/survey'
import { useAppDispatch } from 'hooks/reduxHooks'

interface ISubmitQuestion {
  questionnaireId?: number
  type?: string
  questionNumber?: number
  content?: string | number | number[]
}

interface ISurveyParam {
  surveyId?: number
  rewardType?: string
  rewardNum?: number
  cashUnit?: string
}

const Survey: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [surveyData, setSurveyData] = useState<any>({})
  const [answerArray, setAnswerArray] = useState<Array<ISubmitQuestion>>([])
  const [index, setIndex] = useState<number>(1)
  const ref = useRef<SwiperRef>(null)
  const { language, surveyStr, token, requestUrl } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    dispatch(setLoading(true))
    dispatch(setToken(token!))
    dispatch(setRequestUrl(requestUrl!))
    i18n.changeLanguage(language).then()
    let survey: ISurveyParam = JSON.parse(surveyStr!)
    service
      .getSurveyDetail(language as string, survey.surveyId as number)
      .then((res: any) => {
        let data = res.data
        data.rewardNum = survey?.rewardNum
        data.cashUnit = survey?.cashUnit
        data.rewardType = survey?.rewardType
        console.log(data)
        setSurveyData(data)
      })
      .catch(() => {
        Toast.show(t('common.networkError')!)
      })
  }

  const swipePrev = () => {
    let arr = _.dropRight(_.cloneDeep(answerArray))
    console.log(arr)
    setAnswerArray(arr)
    ref.current?.swipePrev()
  }

  const swipeNext = (data: ISubmitQuestion) => {
    let arr = _.cloneDeep(answerArray)
    arr.push(data)
    console.log(arr)
    setAnswerArray(arr)
    ref.current?.swipeNext()
  }

  const submit = (data: ISubmitQuestion) => {
    dispatch(setLoading(true))
    let arr = _.cloneDeep(answerArray)
    arr.push(data)
    console.log(arr)
    let params = getSubmitParam(arr)
    service
      .submitSurvey(params as any)
      .then((res: any) => {
        let rewardData = {
          rewardType: '',
          rewardNum: 0,
          cashUnit: '',
        } as ISurveyParam
        if (!!res?.data) {
          let rewardArr = (res?.data as any)?.participatedDetails
          if (rewardArr.length > 0) {
            let reward = rewardArr[0]
            rewardData.rewardType = reward.rewardType
            rewardData.rewardNum = reward.reward
            rewardData.cashUnit = reward.rewardUint
          }
        }
        navigate(`/surveySubmitSuccess/${JSON.stringify(rewardData)}`)
      })
      .catch((e) => {
        console.log(e)
        Toast.show(t('survey.submitFailed')!)
      })
  }

  const getSubmitParam = (arr: Array<ISubmitQuestion>) => {
    let result: ISubmitQuestion[] = []
    arr = _.filter(arr, function (o) {
      return !!o.content
    })
    arr.forEach((item) => {
      if (Type.MULTIPLE_CHOICE === item.type) {
        ;(item.content as Array<number>).forEach((number) => {
          let newItem = _.cloneDeep(item)
          newItem.content = number
          result.push(newItem)
        })
      } else {
        result.push(item)
      }
    })
    return result
  }

  const items = surveyData?.questions?.map(
    (question: any, index: React.Key | null | undefined) => {
      return (
        <Swiper.Item key={index}>
          <SurveyComponent
            {...question}
            len={surveyData?.questions?.length}
            swipePrev={swipePrev}
            swipeNext={swipeNext}
            submit={submit}
            questionnaireId={surveyData?.questionnaireId}
          />
        </Swiper.Item>
      )
    }
  )

  return (
    <>
      {!!surveyData?.questionnaireId ? (
        <div className="surveyRoot">
          <div className="line1">{surveyData.title}</div>
          {!!surveyData?.rewardNum ? (
            <span className="span">
              {t('survey.questionTips1')}
              <span className="span span_bl">
                {surveyData?.rewardType === 'POINTS_REWARD'
                  ? t('survey.hasRewardPoints2', {
                      num: surveyData.rewardNum,
                    })
                  : t('survey.hasRewardCash2', {
                      num: surveyData.rewardNum,
                      unit: surveyData.cashUnit,
                    })}
              </span>
              {t('survey.questionTips3')}
            </span>
          ) : (
            <span className="span">{t('survey.thankYou')}</span>
          )}
          <ProgressBar
            className="processBar"
            percent={(index / surveyData?.questions?.length) * 100}
          />
          <span className="step">
            {index}
            {`/${surveyData?.questions?.length}`}
          </span>
          <div className="divider" />
          <div className="surveyContent">
            <Swiper
              ref={ref}
              allowTouchMove={false}
              style={{ '--height': '100%' }}
              indicator={() => null}
              onIndexChange={(index) => setIndex(index + 1)}
            >
              {items}
            </Swiper>
          </div>
        </div>
      ) : null}
    </>
  )
}
export default Survey
