import React, { useEffect } from 'react'
import './SurveySubmitSuccess.scss'
import hookInCircle from 'static/images/hookInCircle.png'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const SurveySubmitSuccess = () => {
  const { t } = useTranslation()
  const { rewardData } = useParams()
  const rewardDataObj = JSON.parse(rewardData!)

  const goToHome = () => {
    try {
      window.parent.postMessage('goToHome', '*')
      window.ReactNativeWebView.postMessage('goToHome')
    } catch (e) {}
  }
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage('hideBackButton')
    } catch (e) {}
    init()
  }, [])

  const init = () => {}

  return (
    <div className={'root'}>
      <img className={'icon'} src={hookInCircle} />
      <span className={'submittedSuccessfully'}>
        {t('survey.submittedSuccessfully')}
      </span>
      <span className={'thankYou'}>{t('survey.thankYou')}</span>
      {rewardDataObj?.rewardNum > 0 ? (
        <span className={'youEarnedPoints1'}>
          {t('survey.youEarnedPoints1')}
          <span className={'youEarnedPoints2'}>
            {rewardDataObj.rewardType === 'POINTS_REWARD'
              ? t('survey.youEarnedPoints2', { num: rewardDataObj.rewardNum })
              : t('survey.youEarnedCash2', {
                  unit: rewardDataObj.cashUnit,
                  num: rewardDataObj.rewardNum,
                })}
          </span>
          {t('survey.youEarnedPoints3')}
        </span>
      ) : null}
      <div
        onClick={() => {
          goToHome()
        }}
        className={'button-primary'}
        style={{ width: '30rem', marginTop: '4rem' }}
      >
        {t('survey.goToHome')}
      </div>
    </div>
  )
}
export default SurveySubmitSuccess
