import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en.json'
import zh from './locales/zh.json'
import tw from './locales/zh_hant.json'
import ar from './locales/ar.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import hi from './locales/hi.json'
import id from './locales/id.json'
import ms from './locales/ms.json'
import pt from './locales/pt.json'
import te from './locales/te.json'
import th from './locales/th.json'
import vi from './locales/vi.json'

const resources: Record<string, { translation: object }> = {
  en: { translation: en }, //英语
  zh: { translation: zh }, //简体中文
  'zh-CN': { translation: zh }, //简体中文
  'zh-TW': { translation: tw }, //繁体中文
  ar: { translation: ar }, //阿拉伯语
  es: { translation: es }, //西班牙语
  fr: { translation: fr }, //法语
  hi: { translation: hi }, //印度语
  id: { translation: id }, //印度尼西亚语
  ms: { translation: ms }, //马来西亚语
  'pt-BR': { translation: pt }, //巴西葡萄牙语
  te: { translation: te }, //泰卢固语
  th: { translation: th }, //泰语
  vi: { translation: vi }, //越南语
  pt: { translation: pt }, //巴西葡萄牙语
  zh_hant: { translation: tw }, //繁体中文
  hindi: { translation: hi }, //印度语
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => {})

i18n.on('languageChanged', onLanguageChanged)

function onLanguageChanged() {}

export default i18n
