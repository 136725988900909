import React, { useEffect, useState } from 'react'
import './Welcome.scss'
import { useTranslation } from 'react-i18next'
import { Checkbox, Image, Input, Toast } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router-dom'
import { setLoading } from 'redux/reducer/common'
import { useAppDispatch } from 'hooks/reduxHooks'
import banner from 'static/images/banner.png'
import appIcon from 'static/images/login_scan.png'
import boschLogo from 'static/images/logo.png'
import replace from 'react-string-replace'
import Countdown from 'react-countdown'
import classNames from 'classnames'
import service from 'service/pages/referral'
import { setRequestUrl } from '../../redux/reducer/login'
import utils from '../../utils/utils'

const ReferralByUser: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [inviteName, setInviteName] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [cellPhone, setCellphone] = useState<string>('')
  const [tcChecked, setTcChecked] = useState<boolean>(false)
  const [ppChecked, setPpChecked] = useState<boolean>(false)
  const [marketingChecked, setMarketingChecked] = useState<boolean>(false)
  const [isCounting, setIsCounting] = useState<boolean>(false)
  const [countryCode, setCountryCode] = useState<string>('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const debugShareInfo =
    'eyJjb3VudHJ5IjoiKzg2IiwibG9naW5JZCI6IjEwOTY5NiIsIm1vYmlsZSI6Imk1VEtUb0FzejM1emJQN3ZZUFdlb2xGRTRiTkcxcld5QlVPcC9Qdkh6Rml1YlRpT0ZrWTUvekdmYlJkY2tzWkwiLCJuYW1lIjoiY29uc3VtZXIiLCJjb3VudHJ5VmFsdWUiOiJDTiJ9'

  useEffect(() => {
    if (state?.inviteName) {
      setInviteName(state?.inviteName as string)
    }
    if (state?.baseurl) {
      dispatch(setRequestUrl(state?.baseurl as string))
    }
    if (state?.country) {
      service.getCountryCellPhoneCode().then((res: any) => {
        let data = res.data
        data.forEach((r: any) => {
          if (r.value === state?.country) {
            setCountryCode(r.code)
          }
        })
        console.log('codeCountry', countryCode)
      })
    }

    const storedCountdownValue = localStorage.getItem('countdownValue')
    const storedCountdownTime = localStorage.getItem('countdownTime')
    console.log('currentStoredCountdownTime', storedCountdownTime)
    console.log('currentStoredCountdownTime', storedCountdownValue)
    if (storedCountdownValue && storedCountdownTime) {
      const currentTime = Date.now()
      const remainingTime = Math.max(
        0,
        parseInt(storedCountdownTime) - currentTime
      )
      setIsCounting(remainingTime > 0)
    }
    console.log('typetypestate', state)
  }, [])
  const handleStartCountdown = (): void => {
    setIsCounting(true)
    const countdownTime = Date.now() + 55000
    localStorage.setItem('countdownValue', 'true')
    localStorage.setItem('countdownTime', countdownTime.toString())
  }

  const handleCountdownEnd = (): void => {
    localStorage.removeItem('countdownValue')
    localStorage.removeItem('countdownTime')
    // 其他倒计时结束时的逻辑
    setIsCounting(false)
  }

  const joinBtnClicked = () => {
    console.log('clicked join btn')
    if (!tcChecked || !ppChecked) {
      Toast.show({
        icon: 'fail',
        content: t('referral.tc'),
        position: 'top',
        duration: 2000,
        maskClassName: 'failedMaskClass',
      })
      return
    }
    let sms = 'N'
    if (marketingChecked) {
      sms = 'Y'
    }
    if (verifyPhone(cellPhone)) {
      if (!name) {
        Toast.show({
          icon: 'fail',
          content: t('referral.username'),
          position: 'top',
          duration: 2000,
          maskClassName: 'failedMaskClass',
        })
        return
      }
      if (!utils.IsRAFCountryWithCode) {
        if (!code) {
          Toast.show({
            icon: 'fail',
            content: t('registerForUser.wrongCaptcha'),
            position: 'top',
            duration: 2000,
            maskClassName: 'failedMaskClass',
          })
          return
        }
      }

      let jsonData = {
        mobile: cellPhone,
        data: state?.shareInfo ? state?.shareInfo : debugShareInfo,
        code: code,
        sms: sms,
        name: name,
      }
      dispatch(setLoading(true))
      service
        .submitReferral(jsonData as any)
        .then((res: any) => {
          let data = res.data
          console.log('submitRes', data)
          if (data === '00') {
            // UIkit.modal('#modal-center').show();
            Toast.show({
              icon: 'success',
              content: t('referral.thanks_id'),
              position: 'top',
              duration: 1000,
              maskClassName: 'successMaskName',
              afterClose: () => {
                let item = utils.getCurrentEnvConfig()
                window.location.href = `${item.webServer}/distribute?country=${
                  state?.country ? state?.country : 'unknown'
                }&app=consumer&auto=1`
              },
            })
          } else if (data === '06') {
            // UIkit.modal('#modal-center').show();
            Toast.show({
              icon: 'fail',
              content: t('referral.registered'),
              position: 'top',
              duration: 2000,
              maskClassName: 'failedMaskClass',
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: t('referral.pincode'),
              position: 'top',
              duration: 2000,
              maskClassName: 'failedMaskClass',
            })
            return
          }
        })
        .catch((err) => {
          console.log('referErr', err)
          if (err?.response?.data?.messageList?.length > 0) {
            Toast.show({
              icon: 'fail',
              content: t(
                'errorCode.' + err?.response?.data?.messageList[0].content
              ),
              position: 'top',
              duration: 2000,
              maskClassName: 'failedMaskClass',
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: t('referral.error'),
              position: 'top',
              duration: 2000,
              maskClassName: 'failedMaskClass',
            })
          }
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    } else {
      Toast.show({
        icon: 'fail',
        content: t('referral.incorrect'),
        position: 'top',
        duration: 2000,
        maskClassName: 'failedMaskClass',
      })
      return
    }
  }

  const highlightWord = (match: string, i: number) => (
    <span key={i} className="sellerInviteHighlight">
      <div className="icon">&#xe29b;</div>
      {match}
    </span>
  )

  const replacedText = replace(
    t('referral.inviteMessage', { name: inviteName }) as string,
    inviteName,
    highlightWord
  )

  const highlightTc = (match: string, i: number) => (
    <span
      onClick={() => {
        showtcpp('tc')
      }}
      key={i}
      className="tcPpHighlight"
    >
      {match}
    </span>
  )

  const highlightPp = (match: string, i: number) => (
    <span
      onClick={() => {
        showtcpp('Pp')
      }}
      key={i}
      className="tcPpHighlight"
    >
      {match}
    </span>
  )

  const replacedTc = replace(
    t('referral.TCMessage') as string,
    t('referral.TCTitle') as string,
    highlightTc
  )

  const replacedPp = replace(
    t('referral.PPMessage') as string,
    t('referral.PPTitle') as string,
    highlightPp
  )

  const nameOnChange = (e: any) => {
    setName(e)
  }

  const phoneOnChange = (e: any) => {
    setCellphone(e)
  }

  const codeOnChange = (e: any) => {
    setCode(e)
  }

  const tcChange = (e: any) => {
    setTcChecked(e)
  }

  const ppChange = (e: any) => {
    setPpChecked(e)
  }

  const marketingChange = (e: any) => {
    setMarketingChecked(e)
  }

  const showtcpp = (type: string) => {
    console.log('clickedTcpp')
    if (type === 'tc') {
      navigate('/consumer/clause', {
        state: { type: '0', country: state?.country ? state?.country : 'CN' },
      })
    } else {
      navigate('/consumer/clause', {
        state: { type: '1', country: state?.country ? state?.country : 'CN' },
      })
    }
  }

  const verifyBtnClicked = () => {
    if (!tcChecked || !ppChecked) {
      Toast.show({
        icon: 'fail',
        content: t('referral.tc'),
        position: 'top',
        duration: 2000,
        maskClassName: 'failedMaskClass',
      })
      return
    }
    if (!verifyPhone(cellPhone)) {
      Toast.show({
        icon: 'fail',
        content: t('referral.incorrect'),
        position: 'top',
        duration: 2000,
        maskClassName: 'failedMaskClass',
      })
      return
    }

    service
      .sendReferralCode(
        cellPhone as string,
        state?.shareInfo ? state?.shareInfo : debugShareInfo
      )
      .then((res: any) => {
        let data = res.data
        console.log(data)
        if (data === '00') {
          Toast.show({
            icon: 'success',
            content: t('referral.sent'),
            position: 'top',
            duration: 2000,
            maskClassName: 'successMaskName',
          })
          if (!isCounting) {
            handleStartCountdown()
          }
        }
        if (data === '01') {
          Toast.show({
            icon: 'fail',
            content: t('referral.registered'),
            position: 'top',
            duration: 2000,
            maskClassName: 'failedMaskClass',
          })
        }
        if (data === '02') {
          Toast.show({
            icon: 'fail',
            content: t('referral.invited'),
            position: 'top',
            duration: 2000,
            maskClassName: 'failedMaskClass',
          })
        }
        if (data === '03') {
          Toast.show({
            icon: 'fail',
            content: t('referral.failed'),
            position: 'top',
            duration: 2000,
            maskClassName: 'failedMaskClass',
          })
        }
      })
      .catch((err) => {
        console.log('referErr', err?.response?.data)
        let res = err?.response?.data
        if (res.messageList?.length > 0) {
          Toast.show({
            icon: 'fail',
            content: t('errorCode.' + res.messageList[0].content),
            position: 'top',
            duration: 2000,
            maskClassName: 'failedMaskClass',
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: t('referral.error'),
            position: 'top',
            duration: 2000,
            maskClassName: 'failedMaskClass',
          })
        }
      })
  }

  const verifyPhone = (cellphoneNum: string) => {
    return !!cellphoneNum
  }

  const seconds = (props: any) => {
    return <div className="countDownSeconds">{props.total / 1000}</div>
  }

  return (
    <div className="welcomeRoot">
      <Image className={'banner'} src={banner} fit={'fill'} />
      <div className="welcomeContainer">
        <Image className={'boschLogo'} src={boschLogo} fit={'contain'} />
        <Image className={'appIcon'} src={appIcon} fit={'fill'} />
        <span style={{ marginTop: '2.8rem' }} className="span_bl">
          {t('welcome.welcomeTo')}
        </span>
        <span className="span_bl">{t('welcome.beconnected')}</span>
        <div style={{ minHeight: '3rem', flex: 1 }} />
        <div className="referralBottomContainer bottomContainer">
          <span className="inviteMessage">{replacedText}</span>
          <Input
            className="baseInput"
            value={name}
            onChange={nameOnChange}
            placeholder={t('referral.namePlaceholder') as string}
          />
          {utils.IsRAFCountryWithCode(state?.country as string) ? (
            <div className="phoneContainer">
              <span className="countryCode">{countryCode}</span>
              <Input
                className="baseInput"
                value={cellPhone}
                onChange={phoneOnChange}
                placeholder={t('referral.cellphonePlaceholder') as string}
              />
            </div>
          ) : (
            <div className="phoneContainer">
              <span className="countryCode">{countryCode}</span>
              <Input
                className="baseInput"
                value={cellPhone}
                onChange={phoneOnChange}
                placeholder={t('referral.cellphonePlaceholder') as string}
              />
              <div
                className={classNames([
                  'verifyBtn',
                  isCounting ? 'verifyDisabled' : '',
                ])}
                onClick={verifyBtnClicked}
              >
                {isCounting ? (
                  <Countdown
                    date={parseInt(
                      localStorage.getItem('countdownTime') || '0'
                    )}
                    onComplete={handleCountdownEnd}
                    renderer={seconds}
                  />
                ) : (
                  t('referral.Verify')
                )}
              </div>
            </div>
          )}
          {utils.IsRAFCountryWithCode(state?.country as string) ? null : (
            <Input
              className="baseInput"
              value={code}
              onChange={codeOnChange}
              placeholder={t('referral.codePlaceholder') as string}
            />
          )}
          <div className="joinBtn" onClick={joinBtnClicked}>
            {t('welcome.joinBeconnected')}
          </div>
          <div className="tcPpText">
            <Checkbox
              checked={tcChecked}
              onChange={tcChange}
              // className="tcPpText"
            />
            <span style={{ marginLeft: '1rem' }}>{replacedTc}</span>
          </div>
          <div className="tcPpText">
            <Checkbox checked={ppChecked} onChange={ppChange} />
            <span style={{ marginLeft: '1rem' }}>{replacedPp}</span>
          </div>
          <div className="tcPpText">
            <Checkbox checked={marketingChecked} onChange={marketingChange} />
            <span style={{ marginLeft: '1rem' }}>
              {t('referral.subscribeMessage')}
            </span>
          </div>
          <div className="divider" />
          {/*<span className="subscribeTips">{t('welcome.subscribeTips')}</span>*/}
          {/*<div className="subscribeBtn" onClick={onSubscribeClicked}>*/}
          {/*  {t('welcome.subscribeTitle')}*/}
          {/*</div>*/}
          <span className="allRightInfo">{t('welcome.allRightInfo')}</span>
        </div>
      </div>
    </div>
  )
}
export default ReferralByUser
