// @ts-ignore
import { AxiosRequestConfig } from 'axios'
// @ts-ignore
import { requestHandler } from './index'

// 使用 request 统一调用，包括封装的get、post、put、delete等方法

export const get = <T>(
  url: string,
  params?: object,
  config?: AxiosRequestConfig
) => requestHandler<T>('get', url, params, config)

export const post = <T>(
  url: string,
  params?: object,
  config?: AxiosRequestConfig
) => requestHandler<T>('post', url, params, config)

export const put = <T>(
  url: string,
  params?: object,
  config?: AxiosRequestConfig
) => requestHandler<T>('put', url, params, config)

export const del = <T>(
  url: string,
  params?: object,
  config?: AxiosRequestConfig
) => requestHandler<T>('delete', url, params, config)
