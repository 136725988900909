import { get, post } from '../../axios/request'
import { ISubmitQuestion } from 'component/surveyComponent'

const survey = '/survey-service'

export const getSurveyDetail = (language: string, surveyId: number) => {
  const url = `${survey}/survey/${language}/${surveyId}`
  return get(url)
}
export const submitSurvey = (param: Array<ISubmitQuestion>) => {
  const url = `${survey}/survey/answer/participated`
  return post(url, param)
}
