import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
  token: string
  requestUrl: string
}

const initialState: CounterState = {
  token: '',
  requestUrl: '',
}

export const login = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setRequestUrl: (state, action: PayloadAction<string>) => {
      state.requestUrl = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setToken, setRequestUrl } = login.actions

export default login.reducer
