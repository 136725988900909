import React, { useEffect, useState, useRef } from 'react'
import './VisitTour.scss'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperRef, Image } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/reduxHooks'
import firstPageBg from 'static/images/firstPageBg.png'
import secondPageBg from 'static/images/secondPageBg.png'
// import thirdPageBg from 'static/images/thirdPageBg.png'
import fourthPageBg from 'static/images/fourthPageBg.png'
import boschLogo from 'static/images/logo.png'
import gift from 'static/images/gift.png'
// import right from 'static/images/visitor_tour_right.png'
import classNames from 'classnames'
import { setRequestUrl } from '../../redux/reducer/login'

const VisitorTour: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const ref = useRef<SwiperRef>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  // 获取所有的查询参数
  // 参数说明 shareInfo、country、language、inviteName、baseurl、from
  // shareInfo 由App端生成的分享链接中带过来的信息,包含邀请人信息
  // country 国家代码 如：CN、BR等
  // language 当前语言
  // inviteName from为refer时独有 邀请人信息
  // baseurl 接口请求域名,与当前环境对应,分global和CN
  // from 链接来源 目前有两种值: 1、refer: App端邀请用户注册的来源 2、scanProduct: 扫描工具二维码
  const queryParams: { [key: string]: string } = {}
  const paramsArray = Array.from(searchParams)
  for (const [key, value] of paramsArray) {
    queryParams[key] = value
  }

  useEffect(() => {
    console.log('currentBrowserLanguage', navigator.language)
    dispatch(
      setRequestUrl(
        queryParams.baseurl ? queryParams.baseurl : 'missingBaseurl'
      )
    )
    i18n
      .changeLanguage(
        queryParams.language ? queryParams.language : navigator.language
      )
      .then((r) => {})
    console.log('getState', queryParams)
  }, [])

  const pushNextPage = () => {
    if (currentIndex !== 2) {
      return
    }

    if (queryParams.from === 'refer') {
      navigate('/referral/referralByUser', {
        state: queryParams,
      })
    } else {
      navigate('/referral/welcomeReferral', {
        state: queryParams,
      })
    }

    console.log('ready push next page')
  }

  const indicator = (total: number, current: number): JSX.Element => {
    let arr = []
    for (let i = 0; i < total; i++) {
      arr.push(i)
    }
    console.log(arr)
    return (
      <div className="indicatorContainer">
        {arr.map((item: any) => {
          return (
            <div
              key={item}
              className={classNames([
                'indicatorItem',
                item === current ? 'itemSelected' : 'itemUnselected',
              ])}
            />
          )
        })}
      </div>
    )
  }

  const firstPage = () => {
    return (
      <Swiper.Item key={11}>
        <div className="commonSwipePage">
          <Image className={'swipeBg'} src={firstPageBg} fit={'cover'} />
          <Image className={'boschLogo'} src={boschLogo} fit={'contain'} />
          <div className="firstContainer contentContainer">
            <div className="flex-row">
              <span className="span_bl">{t('visitorTour.register')}</span>
              <Image width={60} height={60} src={gift} fit={'contain'} />
            </div>
            <span className="span_bl">{t('visitorTour.boschTools')}</span>
            <span className="span_bl">{t('visitorTour.toGainMuch')}</span>
            <span className="span_bl">{t('visitorTour.benefits')}</span>
          </div>
        </div>
      </Swiper.Item>
    )
  }
  const secondPage = () => {
    let arr = [
      {
        icon: <div className="icon">&#xe2b1;</div>,
        title: t('visitorTour.ewarranty'),
      },
      {
        icon: <div className="icon">&#xe351;</div>,
        title: t('visitorTour.erepair'),
      },
      {
        icon: <div className="icon">&#xe1da;</div>,
        title: t('visitorTour.assetManagement'),
      },
    ]
    return (
      <Swiper.Item key={21}>
        <div className="commonSwipePage">
          <Image className={'swipeBg'} src={secondPageBg} fit={'cover'} />
          <Image className={'boschLogo'} src={boschLogo} fit={'contain'} />
          <div className="secondContainer contentContainer">
            <span className="span_bl">{t('visitorTour.enjoyDigital')}</span>
            <span className="span_bl">{t('visitorTour.services')}</span>
            <div className="iconContainer">
              {arr.map((item, index) => {
                return (
                  <div className="iconContent">
                    {item.icon}
                    <span className="iconText">{item.title}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Swiper.Item>
    )
  }
  // const thirdPage = () => {
  //   return (
  //     <Swiper.Item key={31}>
  //       <div className="commonSwipePage">
  //         <Image className={'swipeBg'} src={thirdPageBg} fit={'fill'} />
  //         <Image className={'boschLogo'} src={boschLogo} fit={'contain'} />
  //         <div className="thirdContainer contentContainer">
  //           <span className="span_bl">{t('visitorTour.lookingFor')}</span>
  //           <span className="span_bl">{t('visitorTour.Tools')}</span>
  //           <span className="span_bl">{t('visitorTour.weRecommend')}</span>
  //           <span className="span_bl">{t('visitorTour.forYou')}</span>
  //         </div>
  //       </div>
  //     </Swiper.Item>
  //   )
  // }
  const fourthPage = () => {
    let arr = [
      t('visitorTour.userReferral'),
      t('visitorTour.connectivity'),
      t('visitorTour.membership'),
      // t('visitorTour.PtWebShop'),
    ]
    return (
      <Swiper.Item key={41}>
        <div className="commonSwipePage">
          <Image className={'swipeBg'} src={fourthPageBg} fit={'cover'} />
          <Image className={'boschLogo'} src={boschLogo} fit={'contain'} />
          <div className="fourthContainer contentContainer">
            <span className="span_bl">{t('visitorTour.moreFeatures')}</span>
            <span className="span_bl">{t('visitorTour.forYouTo')}</span>
            <span className="span_bl">{t('visitorTour.explore')}</span>
            {/*e6bf*/}
            <div className="iconContainer">
              {arr.map((item, index) => {
                return (
                  <div className="iconContent">
                    {/*<Image*/}
                    {/*  className={'iconRight'}*/}
                    {/*  src={right}*/}
                    {/*  fit={'contain'}*/}
                    {/*/>*/}
                    <div className="smallIcon">&#xe6bf;</div>
                    <span className="iconText">{item}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div onClick={pushNextPage} className="nextButton">{t('visitorTour.next')}</div>
        </div>
      </Swiper.Item>
    )
  }

  return (
    <div className="visitorRoot">
      <Swiper
        ref={ref}
        style={{ '--height': '100%' }}
        direction="vertical"
        indicator={(total, current) => {
          return indicator(total, current)
        }}
        onIndexChange={(index) => {
          setCurrentIndex(index)
        }}
      >
        {firstPage()}
        {secondPage()}
        {/*{thirdPage()}*/}
        {fourthPage()}
      </Swiper>
    </div>
  )
}
export default VisitorTour
