import { get } from '../../axios/request'

const basic = '/basic-service'

export const getCountryCellPhoneCode = () => {
  const url = `${basic}/external/sys/COUNTRY/byType`
  return get(url)
}

export const getClause = (type: string, country: string) => {
  const url = `${basic}/tc/onlineOne/${type}/${country}/CONSUMER`
  return get(url)
}
