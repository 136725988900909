import { get, post } from '../../axios/request'
// @ts-ignore
import { AxiosRequestConfig } from 'axios'
import { getMiniProgramQrCodeParam } from 'interface/distribute/TurnToWechatMiniProgram'

const basic = '/user-service'

export const submitReferral = (param: any) => {
  const url = `${basic}/referral/submit`
  return post(url, param)
}

export const sendReferralCode = (cellphoneNum: any, urlData: any) => {
  const url = `${basic}/referral/${cellphoneNum}/${urlData}/verify`
  return get(url)
}

export const submitAuthorization = (urlData: any, sms: string) => {
  const url = `${basic}/customer/${urlData}/submitAuthorization?sms=${sms}`
  return get(url)
}

export const getMiniProgramQrCode = (param: getMiniProgramQrCodeParam) => {
  let config: AxiosRequestConfig = {
    changeBaseURL: true,
    notNeedToken: true,
    responseType: 'arraybuffer',
  }
  const url = `${basic}/referral/miniProgram/referralCode`
  return post(url, param, config)
}
